import styles from './index.module.scss';
import ourPrograms from '../../assets/images/our-projects.png';
import ufo from '../../assets/icons/ufo.svg';
import scope from '../../assets/icons/scope.svg';
import earth from '../../assets/icons/earth.svg';

export default function OurPrograms({ summerProgramRef }) {
	return (
		<div ref={summerProgramRef} id='summer_program' className={styles['our-programs']}>
			<div className={styles['our-programs-content']}>
				<h3 className={styles['our-programs-content-title']}>Our unique STEMPOWERS program</h3>
				<div className={styles['our-programs-content-wrapper']}>
					<p className={styles['our-programs-content-text']}>Ready to Ignite Your Child’s Inner Innovator?</p>
					<p className={styles['our-programs-content-text']}>
						At STEMPOWERS, we’re redefining excitement with extraordinary programs that unleash imagination and spark curiosity! Give your child the
						opportunity to explore, innovate, and lead with a globally acclaimed program applied successfully in the MENA region.
					</p>
				</div>
				<div>
					<img src={earth} alt='' />
					<p>From hands-on experiments to creative challenges, our camps turn kids into innovators, problem-solvers, and future pioneers.</p>
				</div>
				<div>
					<img src={ufo} alt='' />
					<p>
						Experience unforgettable moments with your little explorers! Our STEM Days are packed with discovery, while Family Nights bring everyone
						together for interactive, mind-blowing experiments and activities.
					</p>
				</div>
				<div>
					<img src={scope} alt='' />
					<p>
						Fuel their passion year-round! Our specialized school programs and STEM clubs transform curious minds into tech-savvy engineers, aspiring
						scientists, and innovative creators, one exciting session at a time.
					</p>
				</div>
			</div>
			<img src={ourPrograms} alt='' className={styles['our-programs-image']} />
		</div>
	);
}
