import styles from './index.module.scss';
import whyJoin from '../../assets/images/why-join.png';
import welcome from '../../assets/images/welcome.png';
import planet from '../../assets/icons/planet.svg';
import more from '../../assets/icons/more.svg';
import magnet from '../../assets/icons/magnet.svg';
import note from '../../assets/icons/note.svg';
import { useNavigate } from 'react-router-dom';

export default function WhyJoin({ setShowModal, whyJoinRef }) {
	const handleGoToRegister = () => {
		setShowModal(true);
	};

	return (
		<section className={styles['why-us']}>
			<div ref={whyJoinRef} className={styles['why-join']}>
				<div className={styles['why-join-content']}>
					<h3 className={styles['why-join-content-title']}>Why should my child join STEMPOWERS?</h3>
					<p className={styles['why-join-content-text']}>
						Our unique STEMPOWERS programs spark children's curiosity combined with unparalleled hands-on experiences, engaging STEM Challenges and
						real-world projects
					</p>
					<div>
						<img src={planet} alt='' />
						<p>
							STEMPOWERS ignite a passion for exploration in young minds.
							{/* <a>watch video <img src={more} alt='' /></a> */}
						</p>
					</div>
					<div>
						<img src={magnet} alt='' />
						<p>We blend STEM challenges with creativity, problem solving, play, and a dash of magic.</p>
					</div>
					<button onClick={handleGoToRegister} className={styles['why-join-action']}>
						register now
					</button>
				</div>
				<img src={whyJoin} className={styles['why-join-image']} />
			</div>
			{/* <div className={styles['welcome']}>
        <img src={welcome} className={styles['welcome-image']} />
        <div className={styles['welcome-content']}>
          <h3 className={styles['welcome-content-title']}>Welcome to STEMPOWERS! </h3>
          <p className={styles['welcome-content-text']}>STEMPOWERS is an institute that provides fun edutainment STEM programs designed to spark children's power of curiosity and creativity through offering a wide range of remarkable experiences that blend science, technology, engineering, mathematics, and art, that will keep them learning and having fun all day long! </p>
          <div>
            <img src={note} alt='' />
            <p>
              <span>Our engaging Summer and Winter programs are perfect</span>
              <span>for super heros of ages 4 to 12.</span>
            </p>
          </div>
        </div>
      </div> */}
		</section>
	);
}
