import styles from './index.module.scss';
import scratsh from '../../assets/icons/scratsh.svg';

export default function Discounts() {
	return (
		<div className={styles['discounts']}>
			<h3 className={styles['discounts-title']}>
				Program Discounts &<br />
				Promotions
			</h3>
			<div className={styles['discounts-cards']}>
				<div className={styles['discounts-card']}>
					<h4 className={styles['discounts-card-title']}>
						<img src={scratsh} className={styles['discounts-card-title-image']} alt='' /> Squads discount
					</h4>
					<p className={styles['discounts-card-text']}>
						Bring your squad to STEMPOWERS Community and enjoy a 15% special group discount on specific Programs
					</p>
				</div>
				<div className={styles['discounts-card']}>
					<h4 className={styles['discounts-card-title']}>
						<img src={scratsh} className={styles['discounts-card-title-image']} alt='' /> Siblings' discounts
					</h4>
					<p className={styles['discounts-card-text']}>Bring your family to STEMPOWERS Community and enjoy 5% on each sibling </p>
				</div>
				<div className={styles['discounts-card']}>
					<h4 className={styles['discounts-card-title']}>
						<img src={scratsh} className={styles['discounts-card-title-image']} alt='' /> Early Birds discounts
					</h4>
					<p className={styles['discounts-card-text']}>
						Places are limited Register two weeks prior to the start of any program to benefit from EARLY BIRDS DISCOUNTS up to 5%
					</p>
				</div>
			</div>
		</div>
	);
}
