import styles from './index.module.scss';
import sctatsh from '../../assets/icons/scratsh.svg';
import { useNavigate } from 'react-router-dom';
import tabImage from '../../assets/images/tab-image.png';

export default function ProgramStations({ setShowModal, tabs, stationsRef, selectedStation, setSelectedStation, selectedTab, setSelectedTab }) {
	const navigate = useNavigate();

	return (
		<div ref={stationsRef} className={styles['stations']}>
			<h3 className={styles['stations-title']}>STEMPOWERS Programs</h3>
			<div className={styles['stations-tabs']}>
				<p
					onClick={() => {
						navigate('/');
						setSelectedTab('stem_camps');
						setSelectedStation(tabs.stem_camps[0]);
					}}
					className={selectedTab === 'stem_camps' ? styles['selected-tab'] : ''}>
					STEM Camps
				</p>
				<p
					onClick={() => {
						navigate('/');
						setSelectedTab('stem_days_family_nights');
						setSelectedStation(tabs.stem_days_family_nights[0]);
					}}
					className={selectedTab === 'stem_days_family_nights' ? styles['selected-tab'] : ''}>
					STEM Days & Family Nights
				</p>
				<p
					onClick={() => {
						navigate('/');
						setSelectedTab('stem_programs_stem_clubs');
						setSelectedStation(tabs.stem_programs_stem_clubs[0]);
					}}
					className={selectedTab === 'stem_programs_stem_clubs' ? styles['selected-tab'] : ''}>
					School Programs & STEM Clubs
				</p>
			</div>
			<div className={styles['stations-container']}>
				<ul className={styles['stations-tab-list']}>
					{tabs &&
						tabs[selectedTab] &&
						tabs[selectedTab]?.map((station, index) => {
							return (
								<li
									onClick={() => {
										setSelectedStation(station);
									}}
									key={index}
									className={`${styles['stations-tab-list-item']} ${selectedStation?.id === station.id ? styles['selected'] : ''}`}>
									<p>{station.title}</p>
								</li>
							);
						})}
				</ul>
				{selectedStation && (
					<div className={styles['stations-tab-container']}>
						<h5 className={styles['stations-tab-title']}>{selectedStation.slogan}</h5>
						{selectedTab === 'stem_camps' && selectedStation.title === 'Signature STEM Camps' && (
							<p className={styles['stations-tab-text']}>
								Backed by partnerships with global leaders and award-winning in STEM education, our camps are designed to ignite young minds and equip
								them with future  skills. With a proven track record of success in the MENA region, including the UAE. STEMPOWERS Camps are the
								ultimate destination for your child’s journey into the world of STEM.
							</p>
						)}
						<div className={styles['stations-tab-content']}>
							<p className={styles['stations-tab-special']}>{selectedStation.question}</p>
							<p className={styles['stations-tab-text']} dangerouslySetInnerHTML={{ __html: selectedStation.description }} />
						</div>
						{selectedStation.what_we_offer && (
							<div className={styles['stations-tab-content']}>
								<p className={styles['stations-tab-age-text']}>
									<span>what we offer:</span>
								</p>
								<p className={styles['stations-tab-age-text']} dangerouslySetInnerHTML={{ __html: selectedStation.what_we_offer }} />
							</div>
						)}
						{selectedStation.robotics_and_coding && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-title']}>Robotics and Coding</p>
								<p className={styles['stations-tab-age-text']} dangerouslySetInnerHTML={{ __html: selectedStation.robotics_and_coding }} />
							</div>
						)}
						{selectedStation.science_solor_power && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-title']}>The Science of Solar Power</p>
								<p className={styles['stations-tab-age-text']} dangerouslySetInnerHTML={{ __html: selectedStation.science_solor_power }} />
							</div>
						)}
						{selectedStation.water_conservation_science && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-title']}>Water Conservation Science</p>
								<p className={styles['stations-tab-age-text']} dangerouslySetInnerHTML={{ __html: selectedStation.water_conservation_science }} />
							</div>
						)}
						{selectedStation.engineering_life_on_mars && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-title']}>Engineering Life on Mars: Lessons from Nature</p>
								<p className={styles['stations-tab-age-text']} dangerouslySetInnerHTML={{ __html: selectedStation.engineering_life_on_mars }} />
							</div>
						)}
						{selectedStation.note_description && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-text']}>{selectedStation.note_description}</p>
							</div>
						)}
						{selectedStation.age_ranges && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-age-title']}>age range</p>
								<p className={styles['stations-tab-age-text']}>{selectedStation.age_ranges}</p>
							</div>
						)}
						{selectedStation.benefits && (
							<div className={styles['stations-tab-benifits']}>
								<p className={styles['stations-tab-benifits-title']}>station benefits</p>
								<p className={styles['stations-tab-benifits-text']}>{selectedStation.benefits}</p>
							</div>
						)}
						{selectedStation.big_ideas && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-benifits-title']}>Big Ideas</p>
								<p className={styles['stations-tab-age-text']}>{selectedStation.big_ideas}</p>
							</div>
						)}
						{selectedStation.design_challenge && (
							<div className={styles['stations-tab-age']}>
								<p className={styles['stations-tab-special']}>Design Challenge</p>
								<p className={styles['stations-tab-age-text']}>{selectedStation.design_challenge}</p>
							</div>
						)}
						<button onClick={() => setShowModal(true)} className={styles['stations-tab-action']}>
							register now
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
