import playIcon from '../../assets/icons/play-icon.svg';

export default function Video({ src, handlePlay }) {
	return (
		<>
			<img onClick={handlePlay} src={playIcon} alt='play' />
			<video onClick={handlePlay}>
				<source src={src} type='video/mp4' />
			</video>
		</>
	);
}
