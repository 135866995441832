import styles from './index.module.scss';
import logo from '../../assets/images/logo.png';
import facebook from '../../assets/icons/facebook.svg';
import whatsapp from '../../assets/icons/whatsapp.svg';
import instagram from '../../assets/icons/instagram.svg';
import discoveryLogo from '../../assets/images/discovery.png';
import sajaLogo from '../../assets/images/saja.png';
import { useNavigate } from 'react-router-dom';

export default function Footer({ contactRef }) {
	const navigate = useNavigate();
	return (
		<>
			<footer className={styles['footer']}>
				<img onClick={() => navigate('/')} className={styles['footer-logo']} src={logo} alt='Logo' />
				<div className={styles['footer-menu-container']}>
					<div className={styles['footer-menu']}>
						<h3>STEM Camps</h3>
						<ul>
							<li onClick={() => navigate('/?tab=stem_camps&station=signature_stem_camps')}>Signature STEM Camps</li>
							<li onClick={() => navigate('/?tab=stem_camps&station=stem_in_playground')}>STEM in Playground</li>
						</ul>
					</div>
					<div className={styles['footer-menu']}>
						<h3>STEM Days & Family Nights</h3>
						<ul>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=mystery_science')}>Mystery science</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=think_like_a_scientist')}>Think Like a Scientist</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=build_like_an_engineer')}>Build like an Engineer</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=code_like_a_programmer')}>Code like a programmer</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=stem_solution_seekers')}>STEM SOLUTION SEEKERS</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights&station=exercise_like_a_pro-athlete')}>exercise like a pro-athlete</li>
						</ul>
					</div>
					<div className={styles['footer-menu']}>
						<h3>School Programs & STEM Clubs</h3>
						<ul>
							<li onClick={() => navigate('/?tab=stem_programs_stem_clubs&station=in-school_and_after_school_programs')}>
								In-School & After School Programs
							</li>
							<li onClick={() => navigate('/?tab=stem_programs_stem_clubs&station=stem_clubs')}>STEM Clubs</li>
						</ul>
					</div>
					<div className={styles['footer-menu-wrapper']}>
						<div ref={contactRef} className={styles['footer-menu']}>
							<h3>Contact us</h3>
							<ul>
								{/* <li>
                <a href='tel:0106 9737528'>
                (+20) 01069737528
                </a>
              </li> */}
								<li>
									<a href='mailto:info@stempowers.com'>info@stempowers.com</a>
								</li>
							</ul>
							<div className={styles['social']}>
								<a target='_blank' href='https://www.facebook.com/profile.php?id=61561353042733' className={styles['social-media']}>
									<img className={styles['social-media-icon']} src={facebook} alt='facebook' />
								</a>
								<a target='_blank' href='https://wa.me/01069737528' className={styles['social-media']}>
									<img className={styles['social-media-icon']} src={whatsapp} alt='whatsapp' />
								</a>
								<a target='_blank' href='https://www.instagram.com/stem.powers?igsh=NmFkcW1yaWlnZjk3' className={styles['social-media']}>
									<img className={styles['social-media-icon']} src={instagram} alt='instagram' />
								</a>
							</div>
						</div>
						<div className={styles['footer-menu']}>
							<h3>Program providers</h3>
							<div className={styles['footer-providers']}>
								<img src={discoveryLogo} alt='discovery' />
								<img src={sajaLogo} alt='saja' />
							</div>
						</div>
					</div>
				</div>
			</footer>
			<div className={styles['copyrights']}>
				<p>all copyrights reserved &copy; 2024</p>
				<p>
					made with passion by <a href='https://uniparticle.com/'>uniparticle</a>
				</p>
			</div>
		</>
	);
}
