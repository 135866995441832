import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './layout';
import './App.css';
import Home from './pages/home';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Snackbar from './components/snackbar';
import WinterRegister from './pages/winterRegister';

function App() {
	const [showModal, setShowModal] = useState(false);
	const [snackbar, setSnackbar] = useState({
		display: false,
		message: '',
		variant: 'success',
	});
	const contactRef = useRef(null);

	const getLayout = (component) => {
		return (
			<Layout contactRef={contactRef} setShowModal={setShowModal}>
				{component}
			</Layout>
		);
	};
	return (
		<>
			<Helmet>
				<title>STEMPOWERS</title>
			</Helmet>
			<BrowserRouter>
				<Routes>
					<Route path='/' element={getLayout(<Home contactRef={contactRef} showModal={showModal} setShowModal={setShowModal} />)} />
					<Route path='/register' element={getLayout(<WinterRegister setSnackbar={setSnackbar} />)} />
				</Routes>
			</BrowserRouter>
			<Snackbar snackbar={snackbar} setSnackbar={setSnackbar} />
		</>
	);
}

export default App;
