import { useForm } from 'react-hook-form';

export function useHookForm(season, type) {
	const {
		register,
		handleSubmit,
		resetField,
		reset,
		formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
		setValue,
		getValues,
		trigger,
		clearErrors,
	} = useForm({ mode: 'onSubmit' });

	if (season === 'winter') {
		if (type === 'school') {
			const schoolNameRegister = register('school_name', {
				onChange: (e) => {
					clearErrors('school_name');
					const school_name = e.target.value;
					const school_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = school_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (school_nameRegex.test(displayValue)) {
						setValue('school_name', displayValue);
					} else {
						setValue('school_name', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('school_name');
					}
				},
				required: {
					value: true,
					message: 'School name is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const contactPersonRegister = register('contact_person', {
				onChange: (e) => {
					clearErrors('contact_person');
					const contact_person = e.target.value;
					const contact_personRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = contact_person.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (contact_personRegex.test(displayValue)) {
						setValue('contact_person', displayValue);
					} else {
						setValue('contact_person', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('contact_person');
					}
				},
				required: {
					value: true,
					message: 'Contact person is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const emailAddressRegister = register('email_address', {
				onChange: (e) => {
					clearErrors('email_address');
					setValue('email_address', e.target.value);
					clearErrors('email_address');
					if (!e.target.value) {
						resetField('email_address');
					}
				},
				required: {
					value: true,
					message: 'Email Address is Required',
				},
				validate: (value) => {
					if (!value) {
						return true;
					} else {
						const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
						return regex.test(value) || 'Email address is not valid';
					}
				},
			});
			const schoolAddressRegister = register('school_address', {
				onChange: (e) => {
					clearErrors('school_address');
					const school_address = e.target.value;
					const school_addressRegex = /^((?:[A-Za-z0-9 \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = school_address.replace(/[^a-zA-Z0-9 \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (school_addressRegex.test(displayValue)) {
						setValue('school_address', displayValue);
					} else {
						setValue('school_address', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('school_address');
					}
				},
				required: {
					value: true,
					message: 'School address is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const roleRegister = register('role', {
				onChange: (e) => {
					clearErrors('role');
					const role = e.target.value;
					const roleRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = role.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (roleRegex.test(displayValue)) {
						setValue('role', displayValue);
					} else {
						setValue('role', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('role');
					}
				},
				required: {
					value: true,
					message: 'Role/Designation is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const phoneRegister = register('phone', {
				onChange: (e) => {
					clearErrors('phone');
					const phoneNumber = e.target.value;
					const trimmedNumber = phoneNumber.replace(/\D/g, '');
					setValue('phone', trimmedNumber);
				},
				required: {
					value: true,
					message: 'mobile number is required',
				},
			});
			return {
				schoolNameRegister,
				contactPersonRegister,
				emailAddressRegister,
				schoolAddressRegister,
				roleRegister,
				phoneRegister,
				isValid,
				isDirty,
				reset,
				errors,
				handleSubmit,
			};
		} else if (type === 'parent') {
			const fullNameRegister = register('full_name', {
				onChange: (e) => {
					clearErrors('full_name');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('full_name', displayValue);
					} else {
						setValue('full_name', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('full_name');
					}
				},
				required: {
					value: true,
					message: 'Camper full name is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const ageRegister = register('age', {
				onChange: (e) => {
					clearErrors('age');
					const age = e.target.value;
					const trimmedAge = age.replace(/\D/g, '');
					setValue('age', trimmedAge);
				},
				required: {
					value: true,
					message: 'Age is required',
				},
				validate: (value) => {
					return (Number(value) >= 4 && Number(value) <= 14) || 'Camper age must be between 4 and 14';
				},
			});
			const parentSchoolNameRegister = register('parent_school_name', {
				onChange: (e) => {
					clearErrors('parent_school_name');
					const school_name = e.target.value;
					const school_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = school_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (school_nameRegex.test(displayValue)) {
						setValue('parent_school_name', displayValue);
					} else {
						setValue('parent_school_name', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('parent_school_name');
					}
				},
				required: {
					value: true,
					message: 'School name is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const parentFullNameRegister = register('parent_full_name', {
				onChange: (e) => {
					clearErrors('parent_full_name');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('parent_full_name', displayValue);
					} else {
						setValue('parent_full_name', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('parent_full_name');
					}
				},
				required: {
					value: true,
					message: 'Guardian full name is required',
				},
				maxLength: {
					value: 80,
					message: 'Max Characters has been exceeded',
				},
			});
			const parentEmailRegister = register('parent_email', {
				onChange: (e) => {
					clearErrors('parent_email');
					setValue('parent_email', e.target.value);
					clearErrors('parent_email');
					if (!e.target.value) {
						resetField('parent_email');
					}
				},
				required: {
					value: true,
					message: 'Guardian email is Required',
				},
				validate: (value) => {
					if (!value) {
						return true;
					} else {
						const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
						return regex.test(value) || 'Guardiam email is not valid';
					}
				},
			});
			const parentPhoneRegister = register('parent_phone_number', {
				onChange: (e) => {
					clearErrors('parent_phone_number');
					const phoneNumber = e.target.value;
					const trimmedNumber = phoneNumber.replace(/\D/g, '');
					setValue('parent_phone_number', trimmedNumber);
				},
				required: {
					value: true,
					message: 'mobile number is required',
				},
			});
			const emergencyPhoneRegister = register('emergency_phone_number', {
				onChange: (e) => {
					clearErrors('emergency_phone_number');
					const phoneNumber = e.target.value;
					const trimmedNumber = phoneNumber.replace(/\D/g, '');
					setValue('emergency_phone_number', trimmedNumber);
				},
				required: {
					value: true,
					message: 'mobile number is required',
				},
			});
			const allergiesExtraRegister = register('allergies_extra', {
				onChange: (e) => {
					clearErrors('allergies_extra');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('allergies_extra', displayValue);
					} else {
						setValue('allergies_extra', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('allergies_extra');
					}
				},
			});

			const relationshipExtraRegister = register('relationship_extra', {
				onChange: (e) => {
					clearErrors('relationship_extra');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('relationship_extra', displayValue);
					} else {
						setValue('relationship_extra', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('relationship_extra');
					}
				},
			});
			const preferredTimingExtraRegister = register('preferred_timing_extra', {
				onChange: (e) => {
					clearErrors('preferred_timing_extra');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z0-9: \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z0-9: \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('preferred_timing_extra', displayValue);
					} else {
						setValue('preferred_timing_extra', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('preferred_timing_extra');
					}
				},
			});
			const specialExtraRegister = register('special_extra', {
				onChange: (e) => {
					clearErrors('special_extra');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('special_extra', displayValue);
					} else {
						setValue('special_extra', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('special_extra');
					}
				},
			});

			const medsExtraRegister = register('meds_extra', {
				onChange: (e) => {
					clearErrors('meds_extra');
					const full_name = e.target.value;
					const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
					const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
					if (full_nameRegex.test(displayValue)) {
						setValue('meds_extra', displayValue);
					} else {
						setValue('meds_extra', displayValue.trim());
					}
					if (!e.target.value) {
						resetField('meds_extra');
					}
				},
			});
			return {
				fullNameRegister,
				ageRegister,
				parentSchoolNameRegister,
				parentFullNameRegister,
				parentEmailRegister,
				parentPhoneRegister,
				emergencyPhoneRegister,
				allergiesExtraRegister,
				specialExtraRegister,
				medsExtraRegister,
				relationshipExtraRegister,
				preferredTimingExtraRegister,
				isValid,
				isDirty,
				errors,
				reset,
				handleSubmit,
			};
		}
	} else {
		const fullNameRegister = register('full_name', {
			onChange: (e) => {
				clearErrors('full_name');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('full_name', displayValue);
				} else {
					setValue('full_name', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('full_name');
				}
			},
			required: {
				value: true,
				message: 'Camper full name is required',
			},
			maxLength: {
				value: 20,
				message: 'Max Characters has been exceeded',
			},
		});

		const schoolNameRegister = register('school_name', {
			onChange: (e) => {
				clearErrors('school_name');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('school_name', displayValue);
				} else {
					setValue('school_name', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('school_name');
				}
			},
			required: {
				value: true,
				message: 'Camper school name is required',
			},
			maxLength: {
				value: 20,
				message: 'Max Characters has been exceeded',
			},
		});

		const ageRegister = register('age', {
			onChange: (e) => {
				clearErrors('age');
				const age = e.target.value;
				const trimmedAge = age.replace(/\D/g, '');
				setValue('age', trimmedAge);
			},
			required: {
				value: true,
				message: 'Age is required',
			},
			validate: (value) => {
				return (Number(value) >= 4 && Number(value) <= 14) || 'Camper age must be between 4 and 14';
			},
		});

		const parentFullNameRegister = register('parent_full_name', {
			onChange: (e) => {
				clearErrors('parent_full_name');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('parent_full_name', displayValue);
				} else {
					setValue('parent_full_name', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('parent_full_name');
				}
			},
			required: {
				value: true,
				message: 'Guardian full name is required',
			},
			maxLength: {
				value: 20,
				message: 'Max Characters has been exceeded',
			},
		});

		const parentRelationshipRegister = register('parent_relationship', {
			onChange: (e) => {
				clearErrors('parent_relationship');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('parent_relationship', displayValue);
				} else {
					setValue('parent_relationship', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('parent_relationship');
				}
			},
			required: {
				value: true,
				message: 'Guardian relation is required',
			},
			maxLength: {
				value: 20,
				message: 'Max Characters has been exceeded',
			},
		});

		const parentPhoneRegister = register('parent_phone_number', {
			onChange: (e) => {
				clearErrors('parent_phone_number');
				const phoneNumber = e.target.value;
				const trimmedNumber = phoneNumber.replace(/\D/g, '');
				setValue('parent_phone_number', trimmedNumber);
			},
			required: {
				value: true,
				message: 'mobile number is required',
			},
		});

		const parentEmailRegister = register('parent_email', {
			onChange: (e) => {
				clearErrors('parent_email');
				setValue('parent_email', e.target.value);
				clearErrors('parent_email');
				if (!e.target.value) {
					resetField('parent_email');
				}
			},
			required: {
				value: true,
				message: 'Guardian email is Required',
			},
			validate: (value) => {
				if (!value) {
					return true;
				} else {
					const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
					return regex.test(value) || 'Guardiam email is not valid';
				}
			},
		});

		const residencyOtherRegister = register('residency_other', {
			onChange: (e) => {
				clearErrors('residency_other');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('residency_other', displayValue);
				} else {
					setValue('residency_other', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('residency_other');
				}
			},
		});

		const participatedExtraRegister = register('participated_extra', {
			onChange: (e) => {
				clearErrors('participated_extra');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('participated_extra', displayValue);
				} else {
					setValue('participated_extra', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('participated_extra');
				}
			},
		});

		const allergiesExtraRegister = register('allergies_extra', {
			onChange: (e) => {
				clearErrors('allergies_extra');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('allergies_extra', displayValue);
				} else {
					setValue('allergies_extra', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('allergies_extra');
				}
			},
		});

		const specialExtraRegister = register('special_extra', {
			onChange: (e) => {
				clearErrors('special_extra');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('special_extra', displayValue);
				} else {
					setValue('special_extra', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('special_extra');
				}
			},
		});

		const medsExtraRegister = register('meds_extra', {
			onChange: (e) => {
				clearErrors('meds_extra');
				const full_name = e.target.value;
				const full_nameRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
				const displayValue = full_name.replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, '').replace(/\s+/g, ' ');
				if (full_nameRegex.test(displayValue)) {
					setValue('meds_extra', displayValue);
				} else {
					setValue('meds_extra', displayValue.trim());
				}
				if (!e.target.value) {
					resetField('meds_extra');
				}
			},
		});

		return {
			handleSubmit,
			errors,
			isDirty,
			isValid,
			fullNameRegister,
			ageRegister,
			schoolNameRegister,
			parentFullNameRegister,
			parentRelationshipRegister,
			parentPhoneRegister,
			parentEmailRegister,
			residencyOtherRegister,
			participatedExtraRegister,
			allergiesExtraRegister,
			specialExtraRegister,
			medsExtraRegister,
			getValues,
			dirtyFields,
			isSubmitting,
			clearErrors,
			setValue,
			trigger,
		};
	}
}
