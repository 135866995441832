import styles from './index.module.scss';
import snowMan from '../../assets/images/snow-man.png';
import registerSecond from '../../assets/images/register-second.png';
import { useHookForm } from '../../hooks/hook-form';
import FormInput from '../../components/formInput';
import { Radio } from '../../components/radio';
import { useEffect, useState } from 'react';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Helmet } from 'react-helmet';
import Loader from '../../components/loader';
import { useNavigate, useSearchParams } from 'react-router-dom';

const availablePayment = [
	{
		name: 'Bank Transfer',
		value: 'bank_transfer',
	},
	{
		name: 'Cash',
		value: 'cash',
	},
];
const availableRelations = [
	{
		name: 'Parent',
		value: 'parent',
	},
	{
		name: 'Guardian',
		value: 'guardian',
	},
	{
		name: 'Relative',
		value: 'relative',
	},
	{
		name: 'Other',
		value: 'other',
	},
];

const grades = [
	'Kindergarten 1 (KG1)',
	'Kindergarten 2 (KG2)',
	'Grade 1',
	'Grade 2',
	'Grade 3',
	'Grade 4',
	'Grade 5',
	'Grade 6',
	'Grade 7',
	'Grade 8',
];

export default function WinterRegister({ setSnackbar }) {
	const [params, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(params.get('type') === 'school' ? 'school' : 'parent');
	const [trigger, setTrigger] = useState(false);
	const [selectedCohort, setSelectedCohort] = useState('');
	const [preferredTiming, setPreferredTiming] = useState('');
	const [selectedGrade, setSelectedGrade] = useState('');
	const [emergency, setEmergency] = useState(null);
	const [allergies, setAllergies] = useState();
	const [meds, setMeds] = useState();
	const [special, setSpecial] = useState();
	const [media, setMedia] = useState();
	const [preferredPayment, setPreferredPayment] = useState('');
	const [parentRelationship, setParentRelationship] = useState('');
	const [preferredProgram, setPreferredProgram] = useState([]);
	const [availablePrograms, setAvailablePrograms] = useState([]);
	const [availableCohorts, setAvailableCohorts] = useState([]);

	const {
		schoolNameRegister,
		contactPersonRegister,
		emailAddressRegister,
		schoolAddressRegister,
		roleRegister,
		phoneRegister,
		parentSchoolNameRegister,
		fullNameRegister,
		ageRegister,
		parentFullNameRegister,
		parentEmailRegister,
		parentPhoneRegister,
		emergencyPhoneRegister,
		relationshipExtraRegister,
		medsExtraRegister,
		specialExtraRegister,
		allergiesExtraRegister,
		preferredTimingExtraRegister,
		isValid,
		isDirty,
		reset,
		errors,
		handleSubmit,
	} = useHookForm('winter', type);

	const isSchoolDisabled = () => {
		return !isDirty || !isValid || !preferredProgram;
	};
	const isParentDisabled = () => {
		return (
			!isDirty ||
			!isValid ||
			!selectedCohort ||
			!selectedGrade ||
			!preferredTiming ||
			!preferredPayment ||
			!parentRelationship ||
			allergies === undefined ||
			meds === undefined ||
			special === undefined ||
			media === undefined
		);
	};
	const handleProgramsChange = (event) => {
		const {
			target: { value },
		} = event;
		setPreferredProgram(value);
	};
	const handleCohortChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedCohort(value);
		setPreferredTiming('');
	};
	const handleTimingChange = (event) => {
		const {
			target: { value },
		} = event;
		setPreferredTiming(value);
	};
	const handlePaymentChange = (event) => {
		const {
			target: { value },
		} = event;
		setPreferredPayment(value);
	};
	const handleGradeChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedGrade(value);
	};
	const handleRelationshipChange = (event) => {
		const {
			target: { value },
		} = event;
		setParentRelationship(value);
	};
	const chooseAllergies = (value) => {
		setAllergies(value);
	};
	const chooseMeds = (value) => {
		setMeds(value);
	};
	const chooseSpecial = (value) => {
		setSpecial(value);
	};
	const chooseMedia = (value) => {
		setMedia(value);
	};

	const handleSchoolFormSubmit = async (data) => {
		if (isSchoolDisabled()) return;
		setLoading(true);
		try {
			const payload = {
				school_name: data.school_name,
				contact_person: data.contact_person,
				phone_number: data.phone,
				preferred_programs: preferredProgram,
				role: data.role,
				email: data.email_address,
				address: data.school_address,
			};
			const response = await fetch('https://app.stempowers.me/api/camp_manager/winter/program/school', {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response.status === 201) {
				setSnackbar({
					display: true,
					message: 'Registration successful',
					variant: 'success',
				});
				reset();
			} else {
				setSnackbar({
					display: true,
					message: 'Registration failed try again later',
					variant: 'error',
				});
			}
		} catch (error) {
			setSnackbar({
				display: true,
				message: 'Registration failed try again later',
				variant: 'error',
			});
		}
		setLoading(false);
	};
	const handleParentFormSubmit = async (data) => {
		if (isParentDisabled()) return;
		setLoading(true);
		try {
			const payload = {
				camper_fullname: data.full_name,
				camper_school_name: data.parent_school_name,
				guardian_fullname: data.parent_full_name,
				guardian_phone_number: data.parent_phone_number,
				emergency_phone_number: data.emergency_phone_number,
				medical_conditions: special,
				medical_conditions_description: special ? data.special_extra : '',
				medication_required: meds,
				medication_description: meds ? data.meds_extra : '',
				allergies: allergies,
				allergies_description: allergies ? data.allergies_extra : '',
				age: data.age,
				grade: selectedGrade,
				relation_to_camper: parentRelationship,
				...(parentRelationship === 'other' && { other_relation_to_camper: data.relationship_extra }),
				preferred_cohort_days: selectedCohort,
				...(preferredTiming !== 'other' && { preferred_timing: preferredTiming }),
				...(preferredTiming === 'other' && { other_preferred_timing: data.preferred_timing_extra }),
				preferred_payment_method: preferredPayment,
				grant_promotional_purpose: media,
				email: data.parent_email,
			};
			const response = await fetch('https://app.stempowers.me/api/camp_manager/winter/program/parent', {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			if (response.status === 201) {
				setSnackbar({
					display: true,
					message: 'Registration successful',
					variant: 'success',
				});
				reset();
			} else {
				setSnackbar({
					display: true,
					message: 'Registration failed try again later',
					variant: 'error',
				});
			}
		} catch (error) {
			setSnackbar({
				display: true,
				message: 'Registration failed try again later',
				variant: 'error',
			});
		}
		setLoading(false);
	};
	const handleGetData = async () => {
		try {
			const schoolResponse = await fetch('https://app.stempowers.me/api/camp_manager/winter/program/school');
			const parentResponse = await fetch('https://app.stempowers.me/api/camp_manager/winter/program/parent');
			const schoolData = await schoolResponse.json();
			const parentData = await parentResponse.json();
			if (schoolResponse.status === 200) {
				setAvailablePrograms(schoolData.winter_programs);
			}
			if (parentResponse.status === 200) {
				setAvailableCohorts(parentData.cohorts);
			}
		} catch (error) {
			setSnackbar({
				display: true,
				message: 'Failed to get data try again later',
				variant: 'error',
			});
		}
	};

	useEffect(() => {
		if (params.get('type') !== 'school' && params.get('type') !== 'parent') {
			navigate('/register?type=parent');
		}
		handleGetData();
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<title>STEMPOWERS | REGISTER</title>
			</Helmet>
			<div className={styles['register']}>
				<h2 className={styles['register-title']}>
					<img className={styles['register-first']} src={snowMan} alt='' />
					{type === 'school' && (
						<p>
							Partnership Registration Form
							<br />
							for Schools
						</p>
					)}
					{type === 'parent' && (
						<p>
							STEMPOWERS's Programs 2025
							<br />
							Registration Form
						</p>
					)}
					<img className={styles['register-second']} src={registerSecond} alt='' />
				</h2>
				{type === 'school' && (
					<form className={styles['register-form']} noValidate onSubmit={handleSubmit(handleSchoolFormSubmit)}>
						<p style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Welcome to the STEMPOWERS Partnership Programs Registration!
							</span>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Get ready to spark your students’ imagination with globally recognized and Award-winning programs designed to inspire the next
								generation of innovators.
							</span>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Choose your preferred Programs!
							</span>
						</p>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['options-label']}>School Information:</p>
							<FormInput
								maxLength={80}
								formRegister={schoolNameRegister}
								error={errors['school_name']}
								label={'School Name'}
								placeholder={'Enter school name'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={schoolAddressRegister}
								error={errors['school_address']}
								label={'School Address'}
								placeholder={'Enter school address'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={contactPersonRegister}
								error={errors['contact_person']}
								label={'Contact Person'}
								placeholder={'Enter contact person'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={emailAddressRegister}
								error={errors['email_address']}
								label={'Email Address'}
								placeholder={'Enter email address'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={roleRegister}
								error={errors['role']}
								label={'Role/Designation'}
								placeholder={'Role/Designation'}
							/>
							<FormInput
								maxLength={11}
								formRegister={phoneRegister}
								error={errors['phone']}
								label={'Phone Number'}
								placeholder={'Contact phone number'}
							/>
						</div>
						<div>
							<div className={styles['register-form-input-wrapper']}>
								<div className={styles['register-form-input-container']}>
									<div className={styles['options-container']}>
										<p className={styles['options-label']}>Preferred Programs</p>
										<Select
											labelId='demo-multiple-chip-label'
											id='demo-multiple-chip'
											multiple
											sx={{
												width: '100%',
												minHeight: '6rem',
												background: '#fff',
												border: '4px solid #000c78',
												borderRadius: '2.125rem',
												outline: 'none !important',
												fontSize: '1.5rem !important',
												borderColor: trigger && !preferredProgram ? '#fb5b5b' : '#000c78',
												'& *': {
													fontFamily: 'Schoolbell !important',
													fontSize: '1.5rem !important',
													border: '0 !important',
													color: '#000c78 !important',
												},
												'& p': {
													fontSize: '1.75rem !important',
													color: '#b3b7bf !important',
												},
											}}
											displayEmpty
											value={preferredProgram}
											onChange={handleProgramsChange}
											input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
											renderValue={(selected) => {
												if (selected.length === 0) return <p>Enter Preferred Program</p>;
												return (
													<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
														{selected.map((id) => (
															<Chip key={id} label={availablePrograms.filter((program) => program.id === id)[0]?.name} />
														))}
													</Box>
												);
											}}>
											{availablePrograms.map((program) => (
												<MenuItem
													key={program.name}
													value={program.id}
													sx={{
														fontFamily: 'Schoolbell !important',
														fontSize: '1.5rem !important',
														border: '0 !important',
														color: '#000c78 !important',
													}}>
													{program.name}
												</MenuItem>
											))}
										</Select>
										{trigger && !preferredProgram && <p className={styles['form-error']}>* you must choose an option</p>}
									</div>
								</div>
							</div>
						</div>
						<div className={styles['options-container']}>
							<p className={styles['register-text']}>Quick contact</p>
							<p className={styles['options-label']}>For more additional Information or Questions Contact us at 01069737528</p>
						</div>
						<button onClick={() => setTrigger(true)} className={styles['register-form-action']} type='submit' disabled={loading}>
							{loading ? <Loader /> : 'Register'}
						</button>
					</form>
				)}
				{type === 'parent' && (
					<form className={styles['register-form']} noValidate onSubmit={handleSubmit(handleParentFormSubmit)}>
						<p style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Welcome to the STEMPOWERS Camp Registration!
							</span>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Get ready to spark your child’s imagination with globally recognized programs designed to inspire the next generation of innovators.
							</span>
							<span style={{ color: '#000' }} className={styles['options-label']}>
								Choose your preferred program and timing below to secure your child’s spot!
							</span>
						</p>
						{/* parent info */}
						<div>
							<p className={styles['options-label']}>Parent/Guardian Information</p>
							<FormInput
								maxLength={80}
								formRegister={parentFullNameRegister}
								error={errors['parent_full_name']}
								label={'Guardian Full Name'}
								placeholder={'Enter full name'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={parentEmailRegister}
								error={errors['parent_email']}
								label={'Email Address'}
								placeholder={'Enter email address'}
							/>
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<div className={styles['register-form-input-container']}>
								<FormInput
									maxLength={80}
									formRegister={parentPhoneRegister}
									error={errors['parent_phone_number']}
									label={'Guardian Phone Number'}
									placeholder={'Contact Phone Number'}
								/>
								<FormInput
									maxLength={80}
									formRegister={emergencyPhoneRegister}
									error={errors['emergency_phone_number']}
									label={'Alternative Phone Number'}
									placeholder={'Contact Phone Number'}
								/>
							</div>
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<div className={styles['options-container']}>
								<p className={styles['options-label']}>Relationship to Camper</p>
								<Select
									labelId='demo-multiple-chip-label'
									id='demo-multiple-chip'
									sx={{
										width: '100%',
										minHeight: '6rem',
										background: '#fff',
										border: '4px solid #000c78',
										borderRadius: '2.125rem',
										outline: 'none !important',
										fontSize: '1.5rem !important',
										borderColor: trigger && !parentRelationship ? '#fb5b5b' : '#000c78',
										'& *': {
											fontFamily: 'Schoolbell !important',
											fontSize: '1.5rem !important',
											border: '0 !important',
											color: '#000c78 !important',
										},
										'& p': {
											fontSize: '1.75rem !important',
											color: '#b3b7bf !important',
										},
									}}
									displayEmpty
									value={parentRelationship}
									onChange={handleRelationshipChange}
									input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
									renderValue={(selected) => {
										if (selected.length === 0) return <p>Relation to camper</p>;
										return (
											<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
												<Chip key={selected} label={availableRelations.filter((relation) => relation.value === selected)[0].name} />
											</Box>
										);
									}}>
									{availableRelations.map((relation) => (
										<MenuItem
											key={relation.name}
											value={relation.value}
											sx={{
												fontFamily: 'Schoolbell !important',
												fontSize: '1.5rem !important',
												border: '0 !important',
												color: '#000c78 !important',
											}}>
											{relation.name}
										</MenuItem>
									))}
								</Select>
								{trigger && !parentRelationship && <p className={styles['form-error']}>* you must choose an option</p>}
								{parentRelationship === 'other' && (
									<FormInput
										maxLength={80}
										formRegister={relationshipExtraRegister}
										error={errors['relationship_extra']}
										placeholder={'Specify Relationship'}
									/>
								)}
							</div>
						</div>
						{/* child info */}
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['options-label']}>Camper's Information:</p>
							<FormInput
								maxLength={80}
								formRegister={fullNameRegister}
								error={errors['full_name']}
								label={'Enter child full name'}
								placeholder={'Enter camper full name'}
							/>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput maxLength={2} formRegister={ageRegister} error={errors['age']} label={'Age'} placeholder={'Age'} />
							<div className={styles['options-container']}>
								<p className={styles['options-label']}>Grade</p>
								<Select
									labelId='demo-multiple-chip-label-grade'
									id='demo-multiple-chip-grade'
									sx={{
										width: '100%',
										minHeight: '6rem',
										background: '#fff',
										border: '4px solid',
										borderRadius: '2.125rem',
										outline: 'none !important',
										fontSize: '1.5rem !important',
										borderColor: trigger && !selectedGrade ? '#fb5b5b' : '#000c78',
										'& *': {
											fontFamily: 'Schoolbell !important',
											fontSize: '1.5rem !important',
											border: '0 !important',
											color: '#000c78 !important',
										},
										'& p': {
											fontSize: '1.75rem !important',
											color: '#b3b7bf !important',
										},
									}}
									displayEmpty
									value={selectedGrade}
									onChange={handleGradeChange}
									input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
									renderValue={(selected) => {
										if (selected.length === 0) return <p>Select grade</p>;
										return (
											<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
												<Chip key={selected} label={selected} />
											</Box>
										);
									}}>
									{grades.map((name) => (
										<MenuItem
											key={name}
											value={name}
											sx={{
												fontFamily: 'Schoolbell !important',
												fontSize: '1.5rem !important',
												border: '0 !important',
												color: '#000c78 !important',
											}}>
											{name}
										</MenuItem>
									))}
								</Select>
								{trigger && !selectedGrade && <p className={styles['form-error']}>Grade is required</p>}
							</div>
						</div>
						<div className={styles['register-form-input-container']}>
							<FormInput
								maxLength={80}
								formRegister={parentSchoolNameRegister}
								error={errors['parent_school_name']}
								label={'Camper School name'}
								placeholder={'Camper school name'}
							/>
						</div>
						<div>
							<p className={styles['options-label']} style={{ marginBlockEnd: '3rem' }}>
								Preferred Cohort:
							</p>
							<div className={styles['register-form-input-wrapper']}>
								<p className={styles['options-label']}>Please Choose your preferred available slots</p>
								<div className={styles['register-form-input-container']}>
									<div className={styles['options-container']}>
										<p className={styles['options-label']}>Cohorts</p>
										<Select
											labelId='demo-multiple-chip-label'
											id='demo-multiple-chip'
											sx={{
												width: '100%',
												minHeight: '6rem',
												background: '#fff',
												border: '4px solid #000c78',
												borderRadius: '2.125rem',
												outline: 'none !important',
												fontSize: '1.5rem !important',
												borderColor: trigger && !selectedCohort ? '#fb5b5b' : '#000c78',
												'& *': {
													fontFamily: 'Schoolbell !important',
													fontSize: '1.5rem !important',
													border: '0 !important',
													color: '#000c78 !important',
												},
												'& p': {
													fontSize: '1.75rem !important',
													color: '#b3b7bf !important',
												},
											}}
											displayEmpty
											value={selectedCohort}
											onChange={handleCohortChange}
											input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
											renderValue={(selected) => {
												if (selected.length === 0) return <p>Select 5-day cohort</p>;
												return (
													<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
														<Chip key={selected} label={availableCohorts.filter((cohort) => cohort.id === selected)[0].title} />
													</Box>
												);
											}}>
											{availableCohorts.map((cohort) => (
												<MenuItem
													key={cohort.title}
													value={cohort.id}
													sx={{
														fontFamily: 'Schoolbell !important',
														fontSize: '1.5rem !important',
														border: '0 !important',
														color: '#000c78 !important',
													}}>
													{cohort.title}
												</MenuItem>
											))}
										</Select>
										{trigger && !selectedCohort && <p className={styles['form-error']}>* you must choose an option</p>}
									</div>
									<div className={styles['options-container']}>
										<p className={styles['options-label']}>Timing</p>
										<Select
											labelId='demo-multiple-chip-label'
											id='demo-multiple-chip'
											sx={{
												width: '100%',
												minHeight: '6rem',
												background: '#fff',
												border: '4px solid #000c78',
												borderRadius: '2.125rem',
												outline: 'none !important',
												fontSize: '1.5rem !important',
												borderColor: trigger && !preferredTiming ? '#fb5b5b' : '#000c78',
												'& *': {
													fontFamily: 'Schoolbell !important',
													fontSize: '1.5rem !important',
													border: '0 !important',
													color: '#000c78 !important',
												},
												'& p': {
													fontSize: '1.75rem !important',
													color: '#b3b7bf !important',
												},
											}}
											disabled={!selectedCohort}
											displayEmpty
											value={preferredTiming}
											onChange={handleTimingChange}
											input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
											renderValue={(selected) => {
												if (selected.length === 0) return <p>Select Preferred Timing</p>;
												return (
													<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
														<Chip
															key={selected}
															label={
																selected === 'other'
																	? 'Other'
																	: availableCohorts
																			.filter((cohort) => cohort.id === selectedCohort)[0]
																			?.related_timing?.filter((timing) => timing.id === selected)[0].value
															}
														/>
													</Box>
												);
											}}>
											{availableCohorts
												.filter((cohort) => cohort.id === selectedCohort)[0]
												?.related_timing?.map((timing) => (
													<MenuItem
														key={timing.value}
														value={timing.id}
														sx={{
															fontFamily: 'Schoolbell !important',
															fontSize: '1.5rem !important',
															border: '0 !important',
															color: '#000c78 !important',
														}}>
														{timing.value}
													</MenuItem>
												))}
											<MenuItem
												key={'other'}
												value={'other'}
												sx={{
													fontFamily: 'Schoolbell !important',
													fontSize: '1.5rem !important',
													border: '0 !important',
													color: '#000c78 !important',
												}}>
												Other
											</MenuItem>
										</Select>
										{trigger && !preferredTiming && <p className={styles['form-error']}>* you must choose an option</p>}
									</div>
								</div>
								{selectedCohort && (
									<a
										href={availableCohorts.find((cohort) => cohort.id === selectedCohort).location}
										target='_blank'
										className={styles['register-form-text']}>
										{availableCohorts.find((cohort) => cohort.id === selectedCohort).description}
									</a>
								)}
								{preferredTiming === 'other' && (
									<FormInput
										maxLength={80}
										formRegister={preferredTimingExtraRegister}
										error={errors['preferred_timing_extra']}
										placeholder={'Specify preferred timing'}
									/>
								)}
							</div>
						</div>
						{/* camper needs */}
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text-small']}>Camper Needs</p>
							<p className={styles['register-form-text']}>
								Please let us know if your child has any specific needs, allergies, or medical conditions that require special care during the camp.
								This information will help us ensure your child has a safe and enjoyable experience.
							</p>
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text']}>Does your child have any allergies?</p>
							<div className={styles['register-form-input-container-radio']}>
								<Radio handleClick={chooseAllergies} selected={allergies} id={'allergies-yes'} value={true} label={'Yes'} name={'allergies'} />
								<Radio handleClick={chooseAllergies} selected={allergies} id={'allergies-no'} value={false} label={'No'} name={'allergies'} />
							</div>
							{trigger && allergies === undefined && <p className={styles['form-error']}>* you must select an option</p>}
							{allergies && <FormInput maxLength={100} formRegister={allergiesExtraRegister} placeholder={'Please specify'} />}
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text']}>Does your child have any medical conditions that require special care?</p>
							<div className={styles['register-form-input-container-radio']}>
								<Radio handleClick={chooseSpecial} selected={special} id={'special-yes'} value={true} label={'Yes'} name={'special'} />
								<Radio handleClick={chooseSpecial} selected={special} id={'special-no'} value={false} label={'No'} name={'special'} />
							</div>
							{trigger && special === undefined && <p className={styles['form-error']}>* you must select an option</p>}
							{special && <FormInput maxLength={100} formRegister={specialExtraRegister} placeholder={'Please specify'} />}
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text']}>Does your child require any medications during camp?</p>
							<div className={styles['register-form-input-container-radio']}>
								<Radio handleClick={chooseMeds} selected={meds} id={'meds-yes'} value={true} label={'Yes'} name={'meds'} />
								<Radio handleClick={chooseMeds} selected={meds} id={'meds-no'} value={false} label={'No'} name={'meds'} />
							</div>
							{trigger && meds === undefined && <p className={styles['form-error']}>* you must select an option</p>}
							{meds && <FormInput maxLength={100} formRegister={medsExtraRegister} placeholder={'Please specify'} />}
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text-small']}>Photo/Video Release</p>
							<p className={styles['register-form-text']}>
								STEMPOWERS would like to capture photos and videos during the camp to showcase the activities and learning experiences on our website,
								social media, and promotional materials. Please indicate your preference:
							</p>
						</div>
						<div className={styles['register-form-input-wrapper']}>
							<p className={styles['register-form-text']}>
								I grant permission for STEMPOWERS camp to take images and videos of my child and use them for promotional purposes.{' '}
							</p>
							<div className={styles['register-form-input-container-radio']}>
								<Radio handleClick={chooseMedia} selected={media} id={'media-yes'} value={true} label={'Yes'} name={'media'} />
								<Radio handleClick={chooseMedia} selected={media} id={'media-no'} value={false} label={'No'} name={'media'} />
							</div>
							{trigger && media === undefined && <p className={styles['form-error']}>* you must select an option</p>}
						</div>
						<div>
							<p className={styles['register-form-text']}>
								Note: Photos/videos will be used responsibly, and no personal information will be disclosed.
							</p>
						</div>
						<div>
							<div className={styles['options-container']}>
								<p className={styles['options-label']}>Preferred Payment Method</p>
								<Select
									labelId='demo-multiple-chip-label'
									id='demo-multiple-chip'
									sx={{
										width: '100%',
										minHeight: '6rem',
										background: '#fff',
										border: '4px solid #000c78',
										borderRadius: '2.125rem',
										outline: 'none !important',
										fontSize: '1.5rem !important',
										borderColor: trigger && !preferredPayment ? '#fb5b5b' : '#000c78',
										'& *': {
											fontFamily: 'Schoolbell !important',
											fontSize: '1.5rem !important',
											border: '0 !important',
											color: '#000c78 !important',
										},
										'& p': {
											fontSize: '1.75rem !important',
											color: '#b3b7bf !important',
										},
									}}
									displayEmpty
									value={preferredPayment}
									onChange={handlePaymentChange}
									input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
									renderValue={(selected) => {
										if (selected.length === 0) return <p>Enter Preferred Payment Method</p>;
										return (
											<Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: 0.5 }}>
												<Chip key={selected} label={availablePayment.filter((payment) => payment.value === selected)[0].name} />
											</Box>
										);
									}}>
									{availablePayment.map((payment) => (
										<MenuItem
											key={payment.name}
											value={payment.value}
											sx={{
												fontFamily: 'Schoolbell !important',
												fontSize: '1.5rem !important',
												border: '0 !important',
												color: '#000c78 !important',
											}}>
											{payment.name}
										</MenuItem>
									))}
								</Select>
								{trigger && !preferredPayment && <p className={styles['form-error']}>* you must choose an option</p>}
							</div>
						</div>
						<button onClick={() => setTrigger(true)} className={styles['register-form-action']} type='submit' disabled={loading}>
							{loading ? <Loader /> : 'Register'}
						</button>
					</form>
				)}
			</div>
		</>
	);
}
