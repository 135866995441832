import styles from './index.module.scss';
import { useState } from 'react';
import logo from '../../assets/images/logo.png';
import close from '../../assets/icons/close.svg';
import chevronDown from '../../assets/icons/chevron-down.svg';
import { useNavigate } from 'react-router-dom';

export default function Navbar({ setShowModal }) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const isHome = window.location.pathname === '/';
	const navigate = useNavigate();

	const handleToogleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};
	const handleToogleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};
	const handleGoToRegister = () => {
		setIsMenuOpen(false);
		setShowModal(true);
	};

	return (
		<nav className={styles['navbar']}>
			<div className={styles['navbar-logo']}>
				<img onClick={() => navigate('/')} className={styles['navbar-logo-icon']} src={logo} alt='Logo' />
			</div>
			<ul className={styles['navbar-links']}>
				<li onClick={handleToogleDropdown} className={styles['navbar-menu-link']}>
					Programs & Offerings
					<img src={chevronDown} className={isDropdownOpen ? styles['rotate'] : ''} alt='' />
					{isDropdownOpen && (
						<ul className={styles['navbar-menu-link-dropdown-menu']}>
							<li onClick={() => navigate('/?tab=stem_camps')}>Stem Camps</li>
							<li onClick={() => navigate('/?tab=stem_days_family_nights')}>STEM Days & Family Nights</li>
							<li onClick={() => navigate('/?tab=stem_programs_stem_clubs')}>School Programs & STEM Clubs</li>
						</ul>
					)}
				</li>
				<li onClick={() => navigate('/?tab=stem_programs_stem_clubs')} className={styles['navbar-menu-link']}>
					For Schools
				</li>
				{/* <li className={styles['navbar-link']}>about</li> */}
			</ul>
			<button style={!isHome ? { visibility: 'hidden' } : null} onClick={handleGoToRegister} className={styles['navbar-action']}>
				register now
			</button>
			<button onClick={handleToogleMenu} className={styles['navbar-menu-action']}></button>
			{isMenuOpen && (
				<div className={styles['navbar-menu']}>
					<img src={close} onClick={handleToogleMenu} className={styles['navbar-menu-close']} alt='' />
					<ul className={styles['navbar-menu-links']}>
						<li onClick={handleToogleDropdown} className={styles['navbar-menu-link']}>
							Programs & Offerings
							<img src={chevronDown} className={isDropdownOpen ? styles['rotate'] : ''} alt='' />
						</li>
						{isDropdownOpen && (
							<ul className={styles['navbar-menu-link-dropdown-menu']}>
								<li
									onClick={() => {
										navigate('/?tab=stem_camps');
										handleToogleDropdown();
										handleToogleMenu();
									}}>
									Stem Camps
								</li>
								<li
									onClick={() => {
										navigate('/?tab=stem_days_family_nights');
										handleToogleDropdown();
										handleToogleMenu();
									}}>
									STEM Days & Family Nights
								</li>
								<li
									onClick={() => {
										navigate('/?tab=stem_programs_stem_clubs');
										handleToogleDropdown();
										handleToogleMenu();
									}}>
									School Programs & STEM Clubs
								</li>
							</ul>
						)}
						<li onClick={() => navigate('/?tab=stem_programs_stem_clubs')} className={styles['navbar-menu-link']}>
							For Schools
						</li>
						{/* <li className={styles['navbar-menu-link']}>about</li> */}
						<li className={styles['navbar-menu-link']}>
							<button onClick={handleGoToRegister} className={styles['navbar-menu-link-action']}>
								register now
							</button>
						</li>
					</ul>
				</div>
			)}
		</nav>
	);
}
