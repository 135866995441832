import styles from './index.module.scss';
import readyRight from '../../assets/images/ready-right.png';
import readyLeft from '../../assets/images/ready-left.png';
import readyImage from '../../assets/images/ready-image.png';

export default function ReadyToUnlock({ setShowModal }) {
	return (
		<div className={styles['ready']}>
			<img src={readyRight} className={styles['ready-right']} alt='' />
			<img src={readyLeft} className={styles['ready-left']} alt='' />
			<h3 className={styles['ready-title']}>
				<span>Registration is now open!</span>
				<img src={readyImage} className={styles['ready-image']} alt='' />
				Ready to unlock your child’s coolest powers?
			</h3>
			<p className={styles['ready-text']}>
				Don’t miss this exceptional chance to empower your children and students with STEMPOWERS! Our dynamic programs combine hands-on learning in
				real-world challenges, from sustainability to innovative design, with physical activities like fitness competitions. We creating unforgettable
				experiences that engage both the mind and body, while inspiring creativity and innovation!
			</p>
			<button onClick={() => setShowModal(true)} className={styles['ready-action']}>
				register now
			</button>
		</div>
	);
}
