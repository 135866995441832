import { useEffect, useRef, useState } from 'react';
import Discounts from '../../components/discounts';
import Hero from '../../components/hero';
import OurPrograms from '../../components/ourPrograms';
import ProgramStations from '../../components/programStations';
import ReadyToUnlock from '../../components/readyToUnlock';
import WhyJoin from '../../components/whyJoin';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import VideosContainer from '../../components/videosContainer';
import Announcement from '../../components/announcement';
import { set } from 'react-hook-form';

export default function Home({ contactRef, setShowModal, showModal }) {
	const [tabs, setTabs] = useState(null);
	const [stations, setStations] = useState(null);
	const [selectedStation, setSelectedStation] = useState(null);
	const [selectedTab, setSelectedTab] = useState('stem_camps');
	const location = useLocation();
	const summerProgramRef = useRef(null);
	const renderCount = useRef(1);
	const stationsRef = useRef(null);
	const whyJoinRef = useRef(null);

	const handleGetStations = async () => {
		try {
			const response = await fetch('https://app.stempowers.me/api/camp_manager/program_stations');
			const data = await response.json();
			setTabs(data);
			setSelectedStation(data.stem_camps[0]);
		} catch (error) {
			console.error(error);
		}
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};

	useEffect(() => {
		if (renderCount.current === 1) {
			handleGetStations();
		}
		renderCount.current++;
	}, []);

	useEffect(() => {
		if (location.hash === '#program_details' && summerProgramRef.current) {
			summerProgramRef.current.scrollIntoView({ behavior: 'smooth' });
		} else if (location.hash === '#why_join' && whyJoinRef.current) {
			whyJoinRef.current.scrollIntoView({ behavior: 'smooth' });
		} else if (location.hash === '#contact' && contactRef.current) {
			contactRef.current.scrollIntoView({ behavior: 'smooth' });
		} else if (tabs && stationsRef.current) {
			if (location.search) {
				location.search
					.replace('?', '')
					.split('&')
					.forEach((query) => {
						if (location.search.replace('?', '').split('&').length > 1) {
							const [key, value] = query.split('=');
							if (key === 'tab') {
								console.log(value);
								setSelectedTab(value);
							} else if (key === 'station') {
								const station = tabs[selectedTab].find((station) => station.title.toLowerCase() === value.replace(/_/g, ' ').replace(/and/, '&'));
								setSelectedStation(station);
							}
							const timer = setTimeout(() => {
								stationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
								clearTimeout(timer);
							}, 100);
						} else {
							const [key, value] = query.split('=');
							if (key === 'tab') {
								console.log(value);
								setSelectedTab(value);
								setSelectedStation(tabs[value][0]);
							}
							const timer = setTimeout(() => {
								stationsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
								clearTimeout(timer);
							}, 100);
						}
					});
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [location, tabs, selectedTab]);

	return (
		<>
			<Helmet>
				<title>STEMPOWERS | UNLEACH YOUNG MINDS POTENTIALS</title>
			</Helmet>
			<div className={styles['home']}>
				<Hero />
				<WhyJoin setShowModal={setShowModal} whyJoinRef={whyJoinRef} />
				<div className={styles['home-content']}>
					<OurPrograms summerProgramRef={summerProgramRef} />
					<ProgramStations
						setShowModal={setShowModal}
						tabs={tabs}
						selectedTab={selectedTab}
						setSelectedTab={setSelectedTab}
						selectedStation={selectedStation}
						setSelectedStation={setSelectedStation}
						stationsRef={stationsRef}
					/>
					<ReadyToUnlock setShowModal={setShowModal} />
					<VideosContainer />
					<Discounts />
				</div>
			</div>
			{showModal && <Announcement handleCloseModal={handleCloseModal} />}
		</>
	);
}
